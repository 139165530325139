@use 'sass:map';
@use '@angular/material' as mat;

@include mat.core();

// #FF92B6
$_palettes: (
  primary: (
    0: #000000,
    10: #3e001d,
    20: #5e0f33,
    25: #6d1c3e,
    30: #7c2849,
    35: #8a3455,
    40: #993f61,
    50: #b7587a,
    60: #d67093,
    70: #f58aae,
    80: #ffb1c8,
    90: #ffd9e2,
    95: #ffecf0,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  secondary: (
    0: #000000,
    10: #3e001d,
    20: #5e1133,
    25: #6c1d3e,
    30: #7b2949,
    35: #893455,
    40: #984061,
    50: #b6587a,
    60: #d57194,
    70: #f48bae,
    80: #ffb1c8,
    90: #ffd9e2,
    95: #ffecf0,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  tertiary: (
    0: #000000,
    10: #2e1500,
    20: #4c2700,
    25: #5b3100,
    30: #6c3a00,
    35: #7d4400,
    40: #8e4e00,
    50: #af650f,
    60: #ce7e2a,
    70: #ee9742,
    80: #ffb878,
    90: #ffdcc1,
    95: #ffeee2,
    98: #fff8f5,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral: (
    0: #000000,
    4: #19000d,
    6: #250014,
    10: #3e0021,
    12: #440325,
    17: #540c30,
    20: #5d1137,
    22: #63163b,
    24: #681b40,
    25: #6b1d42,
    30: #7a294e,
    35: #883559,
    40: #974066,
    50: #b5597f,
    60: #d37199,
    70: #f28bb3,
    80: #ffb0cc,
    87: #ffcddd,
    90: #ffd9e4,
    92: #ffe1e9,
    94: #ffe8ee,
    95: #ffecf0,
    96: #fff0f3,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  neutral-variant: (
    0: #000000,
    10: #24191c,
    20: #3a2d30,
    25: #45383b,
    30: #514347,
    35: #5d4f52,
    40: #6a5b5e,
    50: #837377,
    60: #9e8c90,
    70: #b9a7aa,
    80: #d5c2c6,
    90: #f2dde1,
    95: #ffecf0,
    98: #fff8f8,
    99: #fffbff,
    100: #ffffff,
  ),
  error: (
    0: #000000,
    10: #410002,
    20: #690005,
    25: #7e0007,
    30: #93000a,
    35: #a80710,
    40: #ba1a1a,
    50: #de3730,
    60: #ff5449,
    70: #ff897d,
    80: #ffb4ab,
    90: #ffdad6,
    95: #ffedea,
    98: #fff8f7,
    99: #fffbff,
    100: #ffffff,
  ),
);

$_rest: (
  secondary: map.get($_palettes, secondary),
  neutral: map.get($_palettes, neutral),
  neutral-variant: map.get($_palettes,  neutral-variant),
  error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);

$theme: mat.define-theme(
(
  color: (
    theme-type: light,
    // primary: mat.$rose-palette,
    // tertiary: mat.$azure-palette,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
));

$dark-theme: mat.define-theme(
(
  color: (
    theme-type: dark,
    primary: $_primary,
    tertiary: $_tertiary,
  ),
));

$themes: $theme, $dark-theme;

@mixin theme($t) {
  @each $t in $themes {

    // * {
    //   padding: 0px;
    //   margin: 0px;
    // }
    
    h2 {
      color: mat.get-theme-color($t, primary, 40);
      padding: 0px;
      margin: 0px;
    }
    
    mat-toolbar {
      background-color: mat.get-theme-color($t, primary, 40);
    }

    mat-menu mat-icon, 
    mat-menu span {
      color: orange !important; // mat.get-theme-color($t, primary, 100) !important;
    }
    
    mat-toolbar mat-icon {
      color: mat.get-theme-color($t, primary, 100);
    }

    mat-sidenav {
      background-color: mat.get-theme-color($t, neutral, 87);
      width: 170px;
      margin-top: 64px;
      min-height: 260px;
      border-radius: 0px;
    }

    mat-sidenav .menu-buttons button {
      border-radius: 5px;
      margin-top: 5px;
      margin-left: 5px;
      margin-right: 5px;
      align-items: center;
    }

    mat-sidenav .menu-buttons mat-icon {
      transform: scale(1.4);
    }

    mat-sidenav .bottom-button mat-icon {
      color: mat.get-theme-color($t, primary, 40);
      padding: 0;
      margin: 0;
      align-items: baseline;
    }

    mat-sidenav-container {
      height: 100%;
      width: 100%;
    }

    mat-sidenav mat-icon-button {
      border-radius: 5px;
    }

    mat-sidenav-content {
      background-color: mat.get-theme-color($t, primary, 100);
      padding: 10px !important;
    }

    mat-menu-item mat-icon {
      color: mat.get-theme-color($t, primary);
      border-radius: 5px;
    }

    //mat-card

    mat-card, 
    mat-card-header, 
    mat-card-content {
      padding: 0px;
      margin: 0px;
    }

    mat-card {
      color: mat.get-theme-color($t, primary);
      background-color: mat.get-theme-color($t, primary, 99);
      padding: 10px;
      margin-bottom: 10px;
    }
    
    mat-card-content {
      color: #000000;
      padding: 0px !important;
    }

    mat-card-header {
      margin-bottom: 10px;
    }

    mat-button-toggle-group {
      color: mat.get-theme-color($t, primary);
      padding: 0px;
      margin: 0px;
    }

    mat-button-toggle button {
      padding: 0 10px 5px 10px;
      margin: 0px;
      align-content: center;
      height: 32px;
    }

    mat-button-toggle span.mat-button-toggle-label-content {
      padding: 0px;
      margin: 0px;
      align-content: center;
    }

    mat-button-toggle {
      color: mat.get-theme-color($t, secondary, 40);
      background-color: mat.get-theme-color($t, secondary, 100);
      padding: 0px;
      margin: 0px;
    }

    mat-button-toggle.mat-button-toggle-checked {
      color: mat.get-theme-color($t, secondary, 100);
      background-color: mat.get-theme-color($t, secondary, 40);
    }
  }
}

.dark {
  
  @include mat.all-component-colors($dark-theme);

  mat-toolbar {
    background-color: mat.get-theme-color($dark-theme, primary);
  }

  mat-toolbar mat-icon {
    color: mat.get-theme-color($dark-theme, on-primary);
  }

  mat-sidenav {
    background-color: mat.get-theme-color($dark-theme, primary-container);
  }

  mat-sidenav mat-icon {
    color: mat.get-theme-color($dark-theme, primary);
  }

  mat-menu-item {
    color: mat.get-theme-color($dark-theme, primary, 40);
  }

  mat-card {
    color: mat.get-theme-color($dark-theme, on-primary);
    appearance: "outlined";
  }

  mat-button-toggle-group {
    color: mat.get-theme-color($dark-theme, primary);
  }
}

:root {
  @include theme($theme);
  @include theme($dark-theme);
  @include mat.all-component-themes($theme);
}

//------------------------------

.scaleonly:hover {
  transform: scale(1.3);
}

.pulse:hover {
  animation: pulse 1s ease-in-out alternate infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.3);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

.rotate:hover {
  animation: rotate 5s linear alternate infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.shake:hover {
  animation: shake 0.1s cubic-bezier(.36, .07, .19, .97) both alternate infinite;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.updown:hover {
  animation: updown 2s ease alternate infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-100%);
  }

  50% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(-100%);
  }
}


.plane {
  animation: spin 3s linear infinite;
  fill: #FFF;
  transform-origin: 50% 50%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}