* { 
  overflow: hidden; 
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.error {
    color: red;
    text-align: center;
}

mat-divider {
  padding-top: 5px;
  background: none;
  border: none !important;
}

h2.mat-mdc-dialog-title {
  margin-left: 25px;
  padding-bottom: 10px;
  font-weight: 500 !important;
}

mat-form-field {
  width: 220px;
}

mat-select {
  height: 18px !important;
}